<template>
<!--  По пути ищет в навигации нужный раздел и создает массив хлебных крошек, но есть исключения для страниц, которых нет в списке навигации-->
  <div class="page-header">
    <div class="page-header__breadcrumbs">
      <div v-for="(item, index) in getBreadcrumbs" :key="index" class="breadcrumbs__item">
        <router-link :to="item.link">
          {{ item.title }}
        </router-link>
      </div>
    </div>
    <h2 v-if="showPageTitle" class="page-header__title" v-html="getPageTitle"></h2>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    showPageTitle: {
      type: Boolean,
      default: true
    },
    showCurrentPageName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mobileWidth: null
    };
  },
  mounted() {
    window.onresize = () => {
      this.mobileWidth = document.documentElement.clientWidth <= 768;
    };
  },
  computed: {
    getBreadcrumbs() {

      let breadcrumbs = [];
      let navSections = this.$store.getters.getNavSections;
      let currentPath = this.$route.path;
      if (currentPath === "/kvota/rossotrudnichestvo") {
        currentPath = "/kvota";
      }

      if (currentPath === "/sber_credit") {
        breadcrumbs.push({
          title: "Образовательный кредит с государственной поддержкой",
          link: "/sber_credit"
        });
      }

      if (currentPath === "/allreviews") {
        breadcrumbs.push({
          title: this.isEnglish ? "Hotline reviews": "Отзывы о работе горячей линии",
          link: "/allreviews"
        });
      }

      if (currentPath.match(/\/events\/\d*/)) {
        breadcrumbs.push({
          title: "Организациям",
          link: "/orgs"
        });
        breadcrumbs.push({
          title: "Мероприятия",
          link: "/events"
        });
      }

      let navBlock;
      let navSection;
      let navItem;

      const titleKey = this.isEnglish ? "titleEng" : "title";

      navSections.map(nav => {
        nav.sections?.map(section => {
          section.items?.map(item => {
            if (item.link === currentPath) {
              navBlock = nav;
              navSection = section;
              navItem = item;
            } else if (section.link === currentPath) {
              navBlock = nav;
              navSection = section;
            } else if (nav.link === currentPath) {
              navBlock = nav;
            }
          });
        });
      });

      if (navItem) {
        breadcrumbs.push({
          title: navItem[titleKey],
          link: navItem.link
        });
      }
      if (navSection) {
        if (navSection.link !== navBlock.link) {
          breadcrumbs.unshift({
            title: navSection[titleKey],
            link: navSection.link
          });
        }
      }
      if (navBlock) {
        breadcrumbs.unshift({
          title: navBlock[titleKey],
          link: navBlock.link
        });
      }

      const linkToMainObject = {
        title: this.isEnglish ? "Home" : "Главная",
        link: "/"
      };

      breadcrumbs.unshift(linkToMainObject);

      if (this.isMobile && breadcrumbs.length > 2) {
        breadcrumbs[1].title = "...";
      }

      //console.log(breadcrumbs)
      return breadcrumbs;
    },
    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    getPageTitle() {
      let title = this.getBreadcrumbs[this.getBreadcrumbs.length - 1].title;
      if (title === "Образовательный кредит с государственной поддержкой") {
        return "Образовательный кредит<br/> с государственной поддержкой";
      } else {
        return title;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.page-header {

  &__breadcrumbs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .breadcrumbs {
      &__item {
        position: relative;
        margin-bottom: 34px;
        padding-left: 22px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          margin-bottom: 15px;
          padding-left: 14px;
        }

        > a {
          color: $grey-color;
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;

          @media screen and (max-width: $mobile-version) and (orientation: portrait) {
            font-size: 8px;
          }

          &:hover {
            color: $primary-color;
          }
        }

        &::after {
          content: '>';
          background-image: url("../assets/images/breadcrumb-divider.svg");
          position: absolute;
          top: 1px;
          left: 8px;
          background-position: 0px;
          background-repeat: no-repeat;
          color: transparent;

          @media screen and (max-width: $mobile-version) and (orientation: portrait) {
            top: 2px;
            left: 4px;
            background-size: 6px;
          }
        }

        &:nth-child(1) {
          padding-left: 0;
        }

        &:nth-child(1)::after {
          content: "";
        }

        &:last-child > a {
          color: $black-color;
          cursor: default;
        }

        &:nth-child(4) {
          @media screen and (max-width: $mobile-version) and (orientation: portrait) {
            display: none;
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: 30px;
    color: $primary-color;
    font-size: $fontSizeXXL;
    font-weight: 800;
    line-height: 45px;

    @media screen and (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 13px;
      font-size: $fontSizeMobileL;
      line-height: 15px;
    }
  }
}


</style>