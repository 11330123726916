`<template>
  <form  class="review-form" @submit.prevent="onSubmit">
    <div class="review-form__container">
      <input v-if="!isEnglish" id="name" v-model.trim="name" placeholder="Имя" class="review-form__name" :class="{'border-danger' : $v.name.$error}" maxlength="50" @keypress="isLetter($event)">
      <input v-else-if="isEnglish" v-model.trim="name" placeholder="Name" class="review-form__name" :class="{'border-danger' : $v.name.$error}" maxlength="50" @keypress="isLetter($event)">
      <div class="review-form__radio" >
        <input id="man" name="gender" v-model.trim="gender"  :class="{'border-danger' : $v.gender.$error}"  type="radio" value="man" >
        <label v-if="!isEnglish"  for="man" class="review-form__gender">M</label>
        <label v-else for="man" class="review-form__gender">M</label>
        <input id="woman" name="gender" v-model.trim="gender" :class="{'border-danger' : $v.gender.$error && !isMobile}" type="radio" value="woman">
        <label v-if="!isEnglish" for="woman" class="review-form__gender">Ж</label>
        <label v-else for="woman" class="review-form__gender">F</label>
      </div>
    </div>
    <div>
      <div class="new-select-style">
        <select v-if="!isEnglish" id="status" v-model.trim="status" :class="{'border-danger' : $v.status.$error}"
                class="review-form__status">
          <option disabled hidden value="">Статус</option>
          <option v-for="(status, index) in statusru" :key="index" :value="status.value">{{ status.label }}</option>
        </select>
        <select v-else-if="isEnglish" v-model.trim="status"  :class="{'border-danger' : $v.status.$error}" class="review-form__status">
          <option disabled hidden value="">Status</option>
          <option v-for="(status, index) in statuseng" :key="index" :value="status.value">{{ status.label }}</option>
        </select>
        <input v-if="status === 'Представитель организации' && !isEnglish && !isMobile" id="orgName" v-model.trim="orgName"  :class="{'border-danger' : $v.orgName.$error}" placeholder="Краткое наименование организации"
               class="review-form__orgname" maxlength="50">
      </div>
    </div>
    <div class="review-form__review">
      <textarea v-if="!isEnglish"  id="text" v-model.trim="text" placeholder="Текст отзыва..."  :class="{'border-danger' : $v.text.$error}" class="review-form__textform"></textarea>
      <textarea v-else-if="isEnglish" v-model.trim="text"  placeholder="Write your review here" :class="{'border-danger' : $v.text.$error}" class="review-form__textform"></textarea>
      <div v-if="!isEnglish" class="maxcounter">
        {{totalCharacters}}/{{maxLength}}
      </div>
      <div v-if="isEnglish" class="maxcounterEng">
        {{totalCharacters}}/{{maxLength}}
      </div>
    </div>
    <div v-if="!isEnglish" class="review-form__footer">
      <StarRating v-model="rating" />
      <input type="submit" value="Отправить отзыв" class="review-form__btnform">
    </div>
    <div v-else class="review-form__footer">
      <StarRating v-model="rating" />
      <input type="submit" value="Submit a review" class="review-form__btnformEng">
    </div>
  </form>

</template>

<script>
import StarRating from "@/components/mainPage/StarRating";
import moment from "moment";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: "ReviewsForm",
  components: {StarRating},
  mixins: [validationMixin],
  data() {
    return {
      show__reviews: true,
      statusru: [{
        label: 'Поступающий',
        value: 'Поступающий'
      },
        {
          label: 'Представитель поступающего',
          value: 'Представитель поступающего',
        },
        {
          label: 'Представитель организации',
          value: 'Представитель организации',
        }
      ],
      statuseng: [{
        label: 'Applicant',
        value: 'Applicant'
      },
        {
          label: 'Applicant’s representative',
          value: 'Applicant’s representative',
        }],
      name: '',
      gender: '',
      status: '',
      orgName: '',
      rating: 5,
      text: '',
      maxLength: 2200,
      date: '',
      approve: false,
    }
  },
  validations: {
    name: {required},
    status: {required},
    text: {required},
    gender: {required},
    orgName: {required}
  },

  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[а-яА-Яa-zA-ZЁёәіңғүұқөһӘІҢҒҮҰҚӨҺ\-\s]*$/.test(char)) return true;
      else e.preventDefault();
    },
    onSubmit() {
      this.$v.name.$touch()
      this.$v.status.$touch()
      this.$v.text.$touch()
      this.$v.orgName.$touch()
      this.$v.gender.$touch()
      if ( this.$v.name.$error || this.$v.status.$error || this.$v.text.$error) {
        console.log('Валидация не прошла')
      }

      if (this.name && this.gender && this.status && this.text && this.rating) {
        let formReview = {
          name: this.name,
          gender: this.gender,
          status: this.status,
          orgName: this.orgName,
          text: this.text,
          rating: this.rating,
          approve: this.approve,
          date: !this.date ? moment() : 'null'
        }
        this.$emit('review-submitted', formReview);
      }
    },
  },
  computed: {
    totalCharacters() {
      return this.text.length
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    }
  },
  watch: {
    text() {
      this.text = this.text.substring(0, this.maxLength)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

::placeholder {
  color: black;
  opacity: 1;
  font-size: 18px;
}

textarea {
  resize: none;
  &.border-danger {
    border: 2px solid red;
  }
}

input {
  &.border-danger {
    border: 2px solid red;
  }
}

select {
  &.border-danger {
    border: 2px solid red;
  }
}

input[type=radio] {
  &.border-danger {
    border: 2px solid red;
    height: 19px;
    width: 18px;
    position: absolute;
    border-radius: 50%;
    top:9px;
    left: 556px;
    z-index: 2;
    @media (max-width: $mobile-version) and (orientation: portrait) {
      left: 0;
      top:30px;
    }
  }
}

.invalid-feedback-orgName {
  display: block;
  width: 100px;
  height: 10px;
  font-size: 10px;
  color: red;
  position: absolute;
  left:400px;
  top:97px;
  @media (max-width: $mobile-version) and (orientation: portrait) {

  }

}
.invalid-feedback-status {
  display: block;
  width: 100px;
  height: 10px;
  font-size: 10px;
  color: red;
  position: absolute;
  left:40px;
  top:95px;
  @media (max-width: $mobile-version) and (orientation: portrait) {
    left:5px;
    top:75px;
  }
}

.invalid-feedback-text {
  display: block;
  width: 100px;
  height: 10px;
  font-size: 10px;
  color: red;
  position: absolute;
  left:40px;
  top:250px;
  @media (max-width: $mobile-version) and (orientation: portrait) {
    left:5px;
    top:227px;
  }
}

.invalid-feedback-radio {
  display: inline-block;
  width: 100px;
  height: 10px;
  font-size: 10px;
  color: red;
  position: absolute;
  top:27px;
  @media (max-width: $mobile-version) and (orientation: portrait) {
    left:5px;
    top:46px;
  }
}
.invalid-feedback-name {
  display: block;
  width: 100px;
  height: 10px;
  font-size: 10px;
  color: red;
  position: absolute;
  left:40px;
  top:39px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    left:5px;
    top:21px;
  }
}

.new-select-style select {
  -webkit-appearance: none;
  height: 40px;
  width: 335px;
  background-image: url(../../assets/images/arr1.svg);
  background-position: bottom 11px right 10px;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 18px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    height: 21px;
    width: 255px;
    margin-bottom: 12px;
    background-image: url(../../assets/images/arr2.svg);
    background-position: bottom 3px right 8px;
    font-size: 13px;
    line-height: 12px;
  }
}

.review-form {
  position: relative;
  z-index: 3;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    margin-left: 33px;
  }

  &__radio {
    display: inline;
    margin-left: 79px;
    margin-bottom: 11px;
    border-bottom: hidden;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      display: flex;
      margin-left: 0;
      margin-bottom: 5px;
    }
  }

  &__radio label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    line-height: 18px;
    user-select: none;
  }

  &__radio label:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    bottom: 1px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 50%;
    margin-right: 0.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
  }

  /* стили при наведении курсора на радио */
  &__radio input[type=radio]:not(:disabled):not(:checked) + label:hover::before {
    border-color: #b3d7ff;
  }

  /* стили для активной радиокнопки (при нажатии на неё) */
  &__radio:not(:disabled):active + label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  /* стили для радиокнопки, находящейся в фокусе */
  &__radio:focus + label::before {
    box-shadow: 0 0 0 0.1rem rgba(0, 255, 255, 0.25);
  }

  /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
  &__radio input[type=radio]:focus:not(:checked){
    border-color: red;
  }

  /* стили для радиокнопки, находящейся в состоянии checked */
  &__radio input[type=radio]:checked + label::before {
    border: 2px solid #adb5bd;
    background-color: #13387D;
    //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle  fill='%23fff'/%3e%3c/svg%3e");
  }

  /* стили для радиокнопки, находящейся в состоянии disabled */
  &__radioinput[type=radio]:disabled + label::before {
    background-color: #e9ecef;
  }

  &__name {
    height: 40px;
    width: 477px;
    border: 1px solid #818285;
    border-radius: 4px;
    background: #F4F4F4;
    margin-bottom: 16px;
    padding-left: 13px;
    font-weight: 500;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 21px;
      width: 255px;
      font-size: 13px;
      margin-bottom: 9px;
    }
  }

  input::placeholder {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: black;

    @media (max-width: $mobile-version) {
      font-size: 13px;
      line-height: 11px;
    }
    @media (max-width: $mobile-version) and (orientation: landscape){
      font-size: 13px;
      line-height: 11px;
    }
  }

  &__gender {
    width: 19px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    padding-right: 25px;
    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 13px;
      line-height: 12px;
      padding-right: 17px;
    }
  }

  &__textform {
    height: 139px;
    width: 1110px;
    border-radius: 4px;
    background: #F4F4F4;
    border: 1px solid #818285;
    margin-bottom: 16px;
    padding-left: 13px;
    padding-top: 13px;
    font-weight: 500;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 255px;
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  textarea::placeholder {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #000000;

    @media (max-width: $mobile-version) {
      font-size: 13px;
      line-height: 13px;
    }
  }

  &__status {
    height: 40px;
    width: 345px;
    border-radius: 4px;
    background: #F4F4F4;
    border: 1px solid #818285;
    font-weight: 600;
    line-height: 23px;
    color: #000000;
    margin-bottom: 15px;
    padding-left: 13px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 24px;
      width: 255px;
      margin-bottom: 12px;
      font-size: 13px;
    }
  }

  &__orgname {
    display: inline;
    padding-left: 13px;
    margin-left:  10px;
    height: 40px;
    width: 765px;
    background: #F4F4F4;
    border: 1px solid #818285;
    border-radius: 4px;
    font-weight: 500;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 24px;
      width: 255px;
      margin-left: 0;
      padding-left: 5px;
      margin-bottom: 9px;
    }
  }

  &__btnform {
    margin-left: 625px;
    width: 234px;
    height: 50px;
    background: #E3EAF1;
    border-radius: 5px;
    color: #13387D;
    font-size: 17px;
    font-weight: 800;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 37px;
      width: 253px;
      margin-left: 0;
      font-size: 13px;
    }
  }

  &__btnform:hover {
    background: #FFFFFF;
  }

  &__btnformEng {
    margin-left: 625px;
    width: 234px;
    height: 50px;
    background: $primary-color;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 37px;
      width: 253px;
      margin-left: 0;
      font-size: 13px;
      margin-top: 20px;
    }
  }

  &__btnformEng:hover {
    background: #FFFFFF;
    color: $primary-color;
  }

  &__footer {
    display: flex;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      display: inline;
    }
  }

}
.maxcounter {
  position: absolute;
  left: 90.42%;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #C4C4C4;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    position: absolute;
    left: 66%;
    top: 68%;
    bottom: 25%;
    font-size: 12px;
    line-height: 15px;
  }
}
.maxcounterEng {
  position: absolute;
  left: 90.42%;
  top: 68.98%;
  bottom: 20.48%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #C4C4C4;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    position: absolute;
    left: 66%;
    top: 64%;
    bottom: 25%;
    font-size: 12px;
    line-height: 15px;
  }
}


</style>

