<template>
<!--  Окно, предупреждающее о необходимости перевернуть экран, отображается только в портретном виде мобильной версии, пропадает при orientation landscape-->
  <div class="warning-rotate">
    <div class="warning-rotate-hint">
      <span v-if="isEnglish">Rotate your phone screen horizontally</span>
      <span v-else>Переведите телефон <br />
        в горизонтальное положение</span>
    </div>
    <div class="warning-rotate-image-arrows"></div>
    <div class="warning-rotate-image-phone"></div>
  </div>
</template>

<script>
export default {
  name: "WarningPhoneRotate",
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.warning-rotate {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $backgroundColor;
  z-index: 10;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: flex;
  }

  @keyframes phone-image-rotate-arrows {
    0% {
      opacity: 1;
      transform: rotate(0);
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(-90deg);
    }
  }

  @keyframes phone-image-rotate-phone {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-90deg);
    }
  }

  &-image {
    &-arrows {
      width: 50%;
      height: 50%;
      position: absolute;
      background-image: url("../assets/images/warning-rotate-arrows.svg");
      background-size: auto 80%;
      background-repeat: no-repeat;
      background-position: center;
      animation: phone-image-rotate-arrows 5s infinite;
      z-index: 150;
    }

    &-phone {
      width: 50%;
      height: 50%;
      position: absolute;
      background-image: url("../assets/images/warning-rotate-phone.svg");
      background-size: auto 80%;
      background-repeat: no-repeat;
      background-position: center;
      animation: phone-image-rotate-phone 5s infinite;
      z-index: 150;
    }
  }

  &-hint {
    position: absolute;
    top: 15%;
    //left: 0;
    color: $primary-color;
    font-weight: 500;
    text-align: center;
  }
}

</style>