<template>
  <div class="star-rating">
      <label class="star-rating__star" v-for="rating in ratings" :key="rating"
             :class="{'is-selected': ((value >= rating) && value != null), 'is-disabled': disabled}"
             v-on:click="set(rating)" v-on:mouseover="star_over(rating)" v-on:mouseout="star_out" >
        <input class="star-rating star-rating__checkbox" type="radio" :value="rating" :name="name"
               v-model="value" :disabled="disabled" @click="star_out">★</label></div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    'value': null,
    'name': String,
    'id': String,
    'disabled': Boolean,
    'required': Boolean
  },
  data: function () {
    return {
      temp_value: null, //временное значение
      ratings: [1, 2, 3, 4, 5]
    };
  },
  methods: {
    /*
     Наведение мышки
     */
    star_over (index) {
      if (!this.disabled) {
        this.temp_value = this.value;
        this.$emit('input', index)
        // return this.value = index;
      }
    },
    /*
     Отвод мышки
     */
    star_out() {
      if (!this.disabled) {
        this.$emit('input', this.temp_value)
        // return this.value = this.temp_value;
      }
    },
    /*
      Установка рейтинга.
     */
    set(value) {
      if (!this.disabled) {
        this.temp_value = value;
        this.$emit('input', value)
        // return this.value = value;
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.star-rating {
  display: inline;
  width: 250px;
  height: 40px;

  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 39px;
    color: #ABABAB;
    transition: color .2s ease-out;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 30px;
    }

    &:hover {
      cursor: pointer;
    }

    &.is-selected {
      color: #EFA300;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }
}
</style>