<template>
  <div class="container">
    <warning-phone-rotate/>
    <page-header></page-header>
    <v-modal v-if="popUpIsVisible" :review-info="reviewInfo" @close="popUpIsVisible = false"/>
    <button v-if="!isEnglish" @click="buttnChange" class="all-reviews__revbtn">
      {{ show__reviews ? 'Веруться к отзывам' : 'Оставить свой отзыв' }}
    </button>
    <button v-else @click="buttnChange" class="all-reviews__revbtneng">
      {{ show__reviews ? 'Back to all reviews' : 'Add a review' }}
    </button>
    <div class="all-reviews__list" >
      <MainLoader class="loader-center" v-if="loading"/>
<!--      <MainLoader class="loader-center-mobile" v-if="loading && isMobile"/>-->
      <ReviewsForm v-if="show__reviews" @review-submitted="addReview($event)"/>
      <template v-else-if="reviews.length > 0">
        <CardReviews v-for="(reviewInfo, index) in sortFunc" :key="index"
                     :review-info="reviewInfo" @click-inside="openPopUp(reviewInfo)"/>
      </template>
      <div v-else>Нет отзывов</div>

      <div v-if="isMobile && !isEnglish" class="otziv" v-show="show__image">
        <img :src="require(`@/assets/images/${otznameS}.svg`)">
      </div>

      <div v-else-if="!isMobile && !isEnglish" class="otziv" v-show="show__image">
        <img :src="require(`@/assets/images/${otzname}.svg`)">
      </div>

      <div v-if="isEnglish && !isMobile" class="otziv" v-show="show__image">
        <img :src="require(`@/assets/images/${engotznameS}.svg`)">
      </div>

      <div v-else-if="isMobile && isEnglish" class="otziv-new" v-show="show__image">
        <img :src="require(`@/assets/images/${engotzname}.svg`)">
      </div>
    </div>
  </div>
</template>

<script>
import CardReviews from "@/components/mainPage/CardReviews";
import ReviewsForm from "@/components/mainPage/ReviewsForm";
import MainLoader from "@/components/MainLoader";
import {getReviews, putReview} from "@/API/reviews";
import VModal from "@/components/pages/vModal";
import WarningPhoneRotate from "@/components/WarningPhoneRotate";
import PageHeader from "@/components/PageHeader";

export default {
  name: "AllReviews",
  components: {PageHeader, VModal, CardReviews, ReviewsForm, MainLoader, WarningPhoneRotate,},

  data() {
    return {
      show__reviews: false,
      loading: false,
      reviews: [],
      show__image: false,
      otzname: 'man-otz',
      otznameS:'man-otzS',
      engotzname: 'engman-otz',
      engotznameS: 'engman-otzS',
      popUpIsVisible: false,
      reviewInfo: null
    }
  },
  created() {
    this.getDataFromServer()
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
    // sortFunc() {
    //   return [...this.reviews].sort((a, b) => new Date(b.date) - new Date(a.date))
    // },
    sortFunc(){
      return this.reviews.filter(() => true).sort((a, b) => new Date(b.date) - new Date(a.date))
    },

    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    },
  },
    methods: {
      openPopUp(reviewInfo) {
        this.reviewInfo = reviewInfo
        this.popUpIsVisible = true
      },
      async getDataFromServer() {
        try {
          this.loading = true;
          this.reviews = await getReviews()
          this.reviews = this.reviews.filter(element => element.approve === true)
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      async addReview(productReview) {
        try {
          this.loading = true;
          await putReview(productReview); // справа кусок кода = ревью от  сервера
          this.otzname = productReview.gender === "man" ? 'man-otz' : 'woman-otz';
          this.otznameS = productReview.gender === "man" ? 'man-otzS' : 'woman-otzS';
          this.engotzname = productReview.gender === "man" ? 'engman-otz' : 'engwoman-otz';
          this.engotznameS = productReview.gender === "man" ? 'engman-otzS' : 'engwoman-otzS';
          this.show__image = true;
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      buttnChange() {
        this.show__reviews = !this.show__reviews
        this.show__image = false
      },
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.otziv {
  position: absolute;
  z-index: 20;
  width: 100%;
  background: #FAFCFF;
  display: flex;
  justify-content: center;

img {
  width: 1110px;
  height: 320px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    width: 335px;
    height: 350px;
    background: #FAFCFF;
  }
}
}

.otziv-new {
  position: absolute;
  z-index: 20;
  width: 100%;
  background: #E3EAF1;
  display: flex;
  justify-content: center;

  img {
    width: 1110px;
    height: 316px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 335px;
      height: 350px;
      background: #E3EAF1;
    }
  }
}


.loader-center {
  width: 1140px;
  height: 300px;
  margin-right: 600px;

}
  .all-reviews {
    &__title {
      display: inline;
      margin-right: 285px;
      font-weight: 800;
      font-size: 36px;
      line-height: 45px;
      color: $primary-color;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        margin-left: 45px;
        font-size: 26px;
        margin-right: 0;
        line-height: 30px;
      }
    }

    &__revbtn {
      width: 234px;
      height: 50px;
      background: #E3EAF1;
      border-radius: 5px;
      color: $primary-color;
      font-size: 17px;
      font-weight: 800;
      cursor: pointer;
      position: absolute;
      left: 875px;
      top:70px;

      @media (max-width: $mobile-version) and (orientation:  portrait) {
        width: 240px;
        height: 35px;
        background: #E3EAF1;
        border-radius: 5px;
        color: $primary-color;
        font-size: 13px;
        font-weight: 800;
        cursor: pointer;
        position: relative;
        left: 55px;
        top:10px;
      }
    }

    &__revbtn:hover {
      background: #FFFFFF;
    }

    &__revbtneng {
      width: 234px;
      height: 50px;
      background: $primary-color;
      border-radius: 5px;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
      position: absolute;
      left: 875px;
      top: 70px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        width: 240px;
        height: 35px;
        background: #E3EAF1;
        border-radius: 5px;
        color: $primary-color;
        font-size: 13px;
        font-weight: 800;
        cursor: pointer;
        position: relative;
        left: 55px;
        top:10px;
      }
    }

    &__revbtneng:hover {
      background: #FFFFFF;
      color: $primary-color;
    }

    &__list {
      display: grid;
      margin-top: 20px;
      grid-template-columns: repeat(2, 1fr);
      gap: 70px 80px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        margin-left: 45px;
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px 80px;
      }
    }
  }

.otzivs {
  position: absolute;
  z-index: 20;
  width: 100%;
  background: $backgroundColor;
  display: flex;
  justify-content: center;

  img {
    width: 1140px;
    height: 316px;
    left: 217px;
  }
}
</style>
